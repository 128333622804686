import { mapState, mapGetters, mapActions } from 'vuex'

// set your computed methods by mapping the state with data and setting our getters to call api;
export const monthlyReportComputed = {
    ...mapState('statistics', {
        monthlyreport:(state) => state.monthly_reports,
    }),
    ...mapGetters('monthlyreport', ['getMonthlyReport'])
}

// export statistics actions
export const monthlyReportMethods = mapActions('monthlyreport', ['allMonthlyReports', 'allMonthlyReportByDateRange', 'createMonthlyReports'
])
